@import './../../globalStyles/variables';

.inview-false {
    .news_item {
        opacity: 0;
        transform: translateY(40px);
    }

 
}

.inview-true {
    .news_item {
        opacity: 1;
        transform: translateY(0px);
    }
 
}

.newslist {
    margin: 0 auto;
    transition: opacity 0.3s ease-in;
    
    .newsitem_mobile {
        display: block;
    }

    .newsitem_desktop {
        display: none;
    }

    .news_item {
        display: flex;
        flex-direction: column;
        width: calc(100vw - 30px);
        margin: 0 auto;
        background-color: #dfe7ef;
        margin-bottom: 40px;

        .news_item_img {
            width: 100%;
        }
        
        .news_item_title {
            font-size: 28px;
            font-weight: 300;
            letter-spacing: 0.8px;
            color: #31404c;
            padding: 30px 35px;
            line-height: 1.2;
        }

        .news_item_content {
            padding: 30px 35px;
            font-size: 14px;
            line-height: 2;
            letter-spacing: 0.7px;
            color: #415165;
        }

        .news_item_readmore {
            padding: 0 35px 50px 30px;
            color: var(--secondary);
        }
    }

    @media screen and (min-width: 1200px) {
        .newsitem_mobile {
            display: none;
        }
    
        .newsitem_desktop {
            display: block;
            .news_item {
                width: 469px;
                margin: 0 auto;
                margin-bottom: 40px;
                height: fit-content;
            }
        }

        width: calc(469px * 2 + 30px);
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 120px;
        div:last-child {
            margin-left:15px
        }

        
    }
}

.news_item {
    transition: opacity 0.2s easy-in, transform 0.4s ease-in;
}
