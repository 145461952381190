@import './../../globalStyles/variables';

.inview-false {
    .connect {
        opacity: 0;
    }

    .connect_content_data, .connect_form {
        transform: translateY(40px);
    }
}

.inview-true {
    .connect {
        opacity: 1;
    }

    .connect_content_data, .connect_form {
        transform: translateY(0px);
    }
}

.connect {
    transition: opacity 0.3s ease-in;
    margin: 0 auto;
    .connect_content {
        padding: 40px 48px;
        background-color: #dfe7ef;
        font-size: 14px;
        line-height: 2;
        letter-spacing: 0.7px;
        color: #58667f;
    }

    .connect_form{
        padding: 50px 48px;

        .form-container {
            padding-top: 30px;
        }

        .contactForm {
            padding-top: 30px;
        }
    }

    .connect-required {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.6px;
        color: #374759;
        text-align: right;
    }


    form {

        input[type=submit] {
            margin-top: 45px;
            color: var(--white);
            background-color: #2f3f4e;
            border: solid 2px #2f3f4e;
            padding: 11px 36px;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.86px;
            text-align: center;
            font-stretch: condensed;
            border-radius: 0;
        }

        input[type=submit]:hover {
            color: var(--primary);
            background-color: #ffffff;
            border: solid 2px #2f3f4e;
        }

        .input input[type="text"]:focus + label {
            transform: translateY(0%) scale(1);
        }

        .input label {
            font-size: 14px;
            font-weight: 900;
            color: #2f3f4e;
            padding-bottom: 5px;
            position: absolute;
            top: 0;
            transform: translateY(0%) scale(0);
            transform-origin: bottom left;
            transition: transform 0.1s linear;
        }

        .input {
            position: relative;
            margin-bottom: 33px;
            margin-top: 33px;
        }

        .input input[type=text] {
        width: 100%; 
        padding: 12px; 
        resize: vertical;
        border-radius: 1px;
        border: solid 2px #2f3f4e;
        background-color: #ffffff;
        display: block;
    }
        
        .input input:focus {
        background-color:#f1e7dd;
        border: none;
        color: #2f3f4e;
        
    }
        .input input[type="text"]:focus + label {
        transform: translateY(-100%) scale(1);
        }
        
        .input input[type="text"]:focus::placeholder {
        opacity: 0;
        }

        .checkbox {
        display: flex;
        margin-bottom: 33px;
        }
        
        .checkbox label {
        font-size: 12px;
        font-weight: 900;
        color: white;
        line-height: 1.5;
        }
        
        .checkbox input[type="checkbox"] {
        margin: 0px;
        margin-right: 14px;
        width: 17px;
        height: 17px;
        min-width: 17px;
        display: block;
        background: transparent;
        appearance: none;
        border: 2px solid var(--primary);
        border-radius: 2px;
        }
          
        .checkbox input[type="checkbox"]:checked {
        background-color:#f1e7dd;
        }

        .checkbox label {
        font-size: 12px;
        font-weight: 900;
        color: var(--primary);
        font-size: 12px;
        letter-spacing: 0.6px;
        color: #2f3f4e;
        }
    }

    @media screen and (min-width: 1200px) {
        .connect_inner {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: 50% 50%;
            --container: 1200px;
            --ratio: 0.5;
            --innerpadding: 147px;
            .connect_content, .connect_form {
                width: 100%;
                .connect_form_data, .connect_content_data, .form-container {
                    width: $internalGridWidthOffset;
                    p {
                        font-size: 14px;
                        line-height: 2;
                        letter-spacing: 0.7px;
                        a {
                            color: #58667f;
                            text-decoration: none;
                        }
                    }
                }
            }
            .connect_content {
                display: flex;
                flex-direction: row-reverse;
                transform: translateY(-72px);
            }
        }

        .connect_form{
            padding-left: 147px;
        }
    }
}


.connect_content_data, .connect_form {
    transition: transform 0.2s ease-in;
    transition-delay: 0.1s;
}
