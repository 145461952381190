@import './../../globalStyles/variables';

.inview-false {
    .features {
        opacity: 0;
    }
    .features_content_inner{
        transform: translateX(40px);
    }
    .features_details {
        transform: translateY(40px);
    }
    
}

.inview-true {
    .features {
        opacity: 1;
    }
    .features_content_inner{
        transform: translateX(0px);
    }
    .features_details {
        transform: translateY(0px);
    }
}

.features {
    transition: opacity 0.2s ease-in;
    position: relative;
    --container: 1200px;
    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 40% auto;
        // transform: translateY(-10%);
    }
    
}
.features_availability {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 5.18px;
    color: var(--primary);
    text-transform: uppercase;
    padding: 20px;
    text-align: center;
    @media screen and (min-width: 1200px) {
        display: flex;
        justify-content: flex-end;
        .features_availability_inner {
            width: $internalGridWidthOffset;
            text-align: left;
            color: white;
            transform: translateY(5%);
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 5.18px;
        }
    }
}

.features_background {
    display: none;
    @media screen and (min-width: 1200px) {
        // transform: translateY(10%);
        display: block;
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        img {
            height: 100%;
            width: 100%;
            object-position: top left;
            object-fit: contain;
        }
        &:after {
            position: absolute;
            height: 100%;
            width: 100%;
            display: block;
            content: "";
            top: 0;
            left: 0;
            background-image: linear-gradient(164deg, rgba(255, 255, 255, 0) 1%, #ffffff 63%);
        }
    }
}

.features_content {
    background-color: #dfe7ef;
    padding: 40px 48px;
    font-size: 14px;
    line-height: 1.86;
    letter-spacing: 0.7px;
    color: #4c5b72;
    --ratio: 0.6;
    --innerpadding: 100px;
    @media screen and (min-width: 1200px) {
        .features_content_inner {
            width: $internalGridWidthOffset;
            p {
                font-size: 14px;
                line-height: 1.86;
                letter-spacing: 0.7px;
                .button {
                    margin-right: 39px;
                }
            }
        }
    }
}



.features_details {
    padding: 50px 110px 50px 48px;
    grid-column-start: 0;
    grid-column-end: 3;
    grid-column-start: 1;
    @media screen and (min-width: 1200px) {
        padding: 50px;
    }
}

.features_detail_title {
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    font-size: 16px;
    letter-spacing: 0.2px;
}

.features_details_heading {
    @media screen and (min-width: 1200px) {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        letter-spacing: 8.05px;
    }
}

.features_detail_description {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.18px;
}

.features_details_list {
    @media screen and (min-width: 1200px) {
        display: grid;
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        .features_details_list_item {

        }
    }
}

.features_content_inner{
    transition: transform 0.2s ease-in;
    transition-delay: 0.1s;
}

.features_details {
    transition: transform 0.5s ease-in;
    transition-delay: 0.3s;
}
