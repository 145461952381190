@import './../../globalStyles/variables';

.inview-false {
    
    .titleandtext {
        transform: translateY(40px);
    }

    .titleandtext_title_inner, .titleandtext_content {
        opacity: 0;
    }
}

.inview-true {

    .titleandtext {
        transform: translateY(0px);
    }

    .titleandtext_title_inner, .titleandtext_content {
        opacity: 1;
    }
}

.titleandtext {
    padding: 40px 48px;

    &.dark-text-false, &.dark-text-false h2, &.dark-text-false h4, &.dark-text-false p {
        color: white;
        a {
            color: white;
        }

        .titleandtext_content_inner p {
            color: white;
        }
    }

    .titleandtext_title_inner h4 {
        font-size: 16px;
        line-height: 1.56;
        letter-spacing: 4.6px;
    }

    .titleandtext_title_inner h2 {
        margin-bottom: 0;
    }

    .titleandtext_content_inner p {
        font-size: 14px;
        color: #4c5b71;
    }

    .titleandtext_content {
        padding: 40px 0;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: 0.8px;
    }

    @media screen and (min-width: 1200px) {
        &.center {
            padding-left: 165px;
            margin: 0 auto;
            .titleandtext_title, .titleandtext_content {
                max-width: 1200px;
                margin: 0 auto;
            }
            .titleandtext_title_inner {
                width: 300px;
            }
            .titleandtext_title_inner h2 {
                margin-bottom: 40px;
            }
            .titleandtext_content_inner {
                color: #415165;
                line-height: 1.86;
                letter-spacing: 0.7px;
                
            }
        }

        &.left {
            display: flex;
            justify-content: center;

            .titleandtext_title {
                padding: 40px 130px 40px 0;
                .titleandtext_title_inner {
                    width: calc((0.4 * 100vw) - 130px - ((100vw - 1200px)/2));
                    h4 {
                        font-size: 16px;
                        line-height: 1.56;
                        letter-spacing: 4.6px;
                        color: #ffffff;
                    }
                }
            }
            .titleandtext_content {
                .titleandtext_content_inner {
                    padding-left: 130px;
                    width: calc((0.6 * 100vw) - 130px - ((100vw - 1200px)/2));

                    p {
                        font-size: 16px;
                    }
                }
            }
        }
        
    }

}


.titleandtext {
    transition: transform 0.3s ease-in;
    transition-delay: 0.15s;
}

.titleandtext_title_inner, .titleandtext_content {
    transition: opacity 0.3s ease-in;
    transition-delay: 0.2s;
}
