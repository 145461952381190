@font-face {
     font-family: 'Flama';
     src: url('./../globalStyles/assets/fonts/Flama/Flama-BookItalic.woff2') format('woff2'),
          url('./../globalStyles/assets/fonts/Flama/Flama-BookItalic.woff') format('woff');
     font-weight: normal;
     font-style: italic;
     font-display: swap;
}

@font-face {
     font-family: 'Flama';
     src: url('./../globalStyles/assets/fonts/Flama/Flama-Book.woff2') format('woff2'),
          url('./../globalStyles/assets/fonts/Flama/Flama-Book.woff') format('woff');
     font-weight: normal;
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: 'Flama';
     src: url('./../globalStyles/assets/fonts/Flama/Flama-Bold.woff2') format('woff2'),
          url('./../globalStyles/assets/fonts/Flama/Flama-Bold.woff') format('woff');
     font-weight: bold;
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: 'Flama';
     src: url('./../globalStyles/assets/fonts/Flama/Flama-Light.woff2') format('woff2'),
          url('./../globalStyles/assets/fonts/Flama/Flama-Light.woff') format('woff');
     font-weight: 300;
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: 'Flama';
     src: url('./../globalStyles/assets/fonts/Flama/Flama-Medium.woff2') format('woff2'),
          url('./../globalStyles/assets/fonts/Flama/Flama-Medium.woff') format('woff');
     font-weight: 500;
     font-style: normal;
     font-display: swap;
}

@font-face {
     font-family: 'Flama';
     src: url('./../globalStyles/assets/fonts/Flama/FlamaCond-Medium.woff2') format('woff2'),
          url('./../globalStyles/assets/fonts/Flama/FlamaCond-Medium.woff') format('woff');
     font-weight: 500;
     font-style: normal;
     font-display: swap;
     font-stretch: condensed;
}