@import './variables';
// BREAKPOINTS MIXIN
@mixin breakpoint($width, $type: min) {
    @if map_has_key($breakpointsfriendly, $width) {
        $width: map_get($breakpointsfriendly, $width);
        @if $type == max {
            $width: $width;
        }
        @media only screen and (#{$type}-width: #{$width}) {
            @content;
        }
    }
}

// CUSTOM BREAKPOINT FOR GRID CONTAINER MAX WIDTH
@mixin gridContainerBreakpoint() {
    @media only screen and (min-width: #{$gridContainer}) {
        @content;
    }
}

// BOX SHADOW AS BORDER (DOESN'T AFFECT BOX SIZING)
@mixin border() {
    box-shadow: inset 0 0 0 1px red;
}





