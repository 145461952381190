.inview-false {
    .floor_details, .floor_top_img, .floor_bottom_img, .floor_details_title, .floor_details_features {
        opacity: 0;
    }
}

.inview-true {
    .floor_details, .floor_top_img, .floor_bottom_img, .floor_details_title, .floor_details_features {
        opacity: 1;
    }
}

.floor_details {
    transition: opacity 0.2s ease-in;
    padding: 50px 0px 67px 47px;
    position: relative;
    &:after {
        content: "";
        background-color: #dfe7ef;
        height: calc(100% - 140px);
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
        display: block;
        z-index: -1;
    }
    .floor_top_img {
        width: 50%;
        transition: opacity 0.2s ease-in;
        transition-delay: 0.2s;
    }

    .floor_bottom_img {
        margin-top: -80px;
        transition: opacity 0.2s ease-in;
        transition-delay: 0.5s;
    }

    .floor_details_title {
        transition: opacity 0.2s ease-in;
        transition-delay: 0.7s;
    }

    .floor_details_features {
        width: 200px;
        transition: opacity 0.2s ease-in;
        transition-delay: 0.9s;
    }
    &.desktop {
        display:  none;
    }
}

@media screen and (min-width: 1200px) {
    .floor_details.mobile {
        display: none;
    }
    .floor_details.desktop {
        display: block;
        padding: 0;
        &:after {
            width: 50%;
            height: 50%;
            bottom: unset;
            top:  20%;
        }
        &.right {
            &:after {
                width: 60%;
                height: 60%;
                left: unset;
                right: 0;
            }
            padding-bottom: 120px;;
            .floor_bottom_img {
                transform: translate(-380px, 100px);
            }
            .floor_top_img {
                .zoom-false img {
                    margin-left: 100px;
                }
            }
        }
        .container {
            max-width: 1200px;
            width: 1200px;
            display: flex;
            flex-direction: row-reverse;
            &.right {
                flex-direction: row;
                .floor_details_images .floor_bottom_img .zoom-false > img {
                    padding-top: 30px;
                    margin-top: -235px;
                    transform: scale(1.2);
                }
            }

            &.left {
                .floor_details_images .floor_bottom_img .zoom-false > img {
                    transform: scale(1.2);
                }
            }
        }
        .floor_details_content {
            width: 40%;
            .features_details_list {
                display: block;
            }
        }
        .floor_details_images {
            width: 60%;

            .floor_bottom_img > figure > img {
                padding-top: 30px;
                margin-top: -200px;
            }
        }
    }
}
