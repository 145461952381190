@import './../../globalStyles/variables';

.inview-false {
    .title_text_asset {
        opacity: 0;
    }
    .title_text_asset_content {
        transform: translateY(40px);
    }
    .title_text_asset_title {
        transform: translateX(40px);
    }
}

.inview-true {
    .title_text_asset {
        opacity: 1;
    }
    .title_text_asset_content {
        transform: translateY(0px);
    }
    .title_text_asset_title {
        transform: translateX(0px);
    }
}

.title_text_asset {
    margin-bottom: 40px;
    transition: opacity 0.3s ease-in;

    a {
        color: #415165;
    }

    &.offset {
        .title_text_asset_title {
            padding: 40px 48px;
            transition: transform 0.2s ease-in;
            transition-delay: 0.05s;
        }
        
        .title_text_asset_content {
            padding: 40px 48px 140px 40px;
            font-size: 16px;
            line-height: 1.75;
            letter-spacing: 0.8px;
            color: #415165;
            background-color: #dfe7ef;
            transition: transform 0.3s ease-in;
        }
        
        .title_text_asset_asset {
            padding: 0px 14px;
            margin-top: -120px;
        }

        .title_text_asset_title_inner h2 {
            margin-bottom: 0;
        }
    
        @media screen and (min-width: 1200px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-bottom: 40px;
            padding-top: 45px;
    
            .title_text_asset_title {
                grid-column-start: 2;
                display: flex;
                flex-direction: row;
                align-items: center;
                .title_text_asset_title_inner {
                    width: 500px;
                    padding-left: 140px;
                    transform: translateY(-80px);
                }
            }
            
            .title_text_asset_content {
                grid-column-start: 1;
                grid-row-start: 1;
                display: flex;
                justify-content: flex-end;
                .title_text_asset_content_inner {
                    width: calc((0.5 * 100vw) - 130px - ((100vw - 1200px)/2));
                }
                
            }
    
            .title_text_asset_asset {
                grid-column-start: 1;
                grid-column-end: 3;
                display: flex;
                justify-content: center;
                .title_text_asset_asset_inner {
                    width: 710px;
                    margin-left: 230px;
                }
            }
        }
    }

    // img should be right
    &.right {
        .title_text_asset_title {
            padding: 40px 48px 20px 48px;
        }

        .title_text_asset_content {
            padding: 20px 48px;
            font-size: 14px;
            line-height: 2;
            letter-spacing: 0.7px;
            color: #415165;
        }

        @media screen and (min-width: 1200px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
    
            .title_text_asset_title {
                display: flex;
                justify-content: flex-end;
                .title_text_asset_title_inner {
                    width: calc((0.5 * 100vw) - 130px - ((100vw - 1200px)/2));
                }
            }
            
            .title_text_asset_content {
                display: flex;
                justify-content: flex-end;
                .title_text_asset_content_inner {
                    width: calc((0.5 * 100vw) - 130px - ((100vw - 1200px)/2));
                }
                
            }
    
            .title_text_asset_asset {
                grid-column-start:2;
                grid-row-end:3;
                grid-row-start:1;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                .title_text_asset_asset_inner {
                    margin-top: 80px;
                    max-width: 684px;
                }
            }
        }
    }

    // img should be left
    &.left {
        display: flex;
        flex-direction: column;
        .title_text_asset_title {
            padding: 40px 48px 20px 48px;
            order: 0;
        }

        .title_text_asset_content {
            padding: 20px 48px;
            font-size: 14px;
            line-height: 2;
            letter-spacing: 0.7px;
            color: #415165;
            order: 2;
        }

        .title_text_asset_asset {
            order: 1;
            padding: 0 14px;
        }

        .title_text_asset_title, .title_text_asset_content, .title_text_asset_asset {
            background-color: #dfe7ef;
        }

        @media screen and (min-width: 1200px) {
            display: grid;
            grid-template-columns: 40% auto;
    
            .title_text_asset_title {
                background-color: #dfe7ef;
                height: fit-content;
                display: flex;
                justify-content: center;
                .title_text_asset_title_inner {
                    width: calc((0.5 * 100vw) - 130px - ((100vw - 1200px)/2));
                }
            }
            
            .title_text_asset_content {
                background-color: #dfe7ef;
                grid-row-start: 2;
                grid-column-start: 2;
                display: flex;
                justify-content: center;
                .title_text_asset_content_inner {
                    width: calc((0.5 * 100vw) - 130px - ((100vw - 1200px)/2));
                }
                
            }
    
            .title_text_asset_asset {
                grid-row-start: 1;
                grid-row-end: 3;
                grid-column-start: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                padding: 0;
                background: none;
                .title_text_asset_asset_inner {
                    margin-right: -10vw;
                }
            }
        }

    }

    // layout - background(with background)
    &.background {
        display: flex;
        flex-direction: column-reverse;
        background-color: #dfe7ef;
        margin-top: -80px;
        margin: 0 auto;

        .title_text_asset_title {
            display: none;
        }
        .title_text_asset_content,
        .title_text_asset_image {
            width: 50%;
        }

        .title_text_asset_asset_inner {
            padding: 0 5px;
        }

        .title_text_asset_content_inner {
            padding-top: 35px;
            padding-left: 47px;
            width: calc(100vw - 47px);
            p {
                color: #415165;
                font-size: 14px;
            }
        }

        @media screen and (min-width: 1200px) {
            flex-direction: row;
            --ratio: 0.5;
            --innerpadding: 100px;
            .title_text_asset_content {
                display: flex;
                flex-direction: row-reverse;
            }

            .title_text_asset_asset_inner {
                padding: 0;
            }

            .title_text_asset_asset {
                width: 517px;
                margin-left: 54px;
            }
            .title_text_asset_content_inner {
                padding-top: 0;
                padding-left: 0;
                padding-right: 175px;
                width: $internalGridWidthOffset;
                p {
                    font-size: 16px;
                }
            }
        }
    }
    
}

    
    @media screen and (min-width: 1200px) {
        // padding: 58px 166px 69px 100px;
        
    }






