@import './../globalStyles/font';
@import 'css-reset-and-normalize/scss/reset-and-normalize';
@import './../globalStyles/variables';
@import './../globalStyles/mixins';
@import './../globalStyles/grid';
@import './../globalStyles/helpers';
@import './../globalStyles/typography';
@import './../globalStyles/base';

.lazyImage {
    height: 600px;
    width: 600px;
}