.animate-true {
    &.main-image {
        animation: fadeInOpacity 1s ease-in 0s 1;
    }
}

.main-image {

    @media screen and (min-width: 1200px) {
        img {
            height: 60vh;
            object-fit: cover;
        }
    }
}

@keyframes fadeInOpacity {
	0% {
        opacity: 0;
        transform: translateY(40px);
	}
	100% {
        opacity: 1;
        transform: translateY(0px);
	}
}
