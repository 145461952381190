@import './../../globalStyles/variables';

.inview-false {
    .title_image_legend, .title, .legend {
        opacity: 0;
    }

    .images_list {
        transform: translateY(40px);
    }

}

.inview-true {
    .title_image_legend, .title, .legend {
        opacity: 1;
    }

    .images_list {
        transform: translateY(0px);
    }

}

.title_image_legend {
    transition: opacity 0.3s ease-in;
    --container: 1200px;
    padding: 60px 0 40px 47px;
    width: calc(100vw - 47px);

    .title_image_legend_header {
        padding-bottom: 40px;
    }

    .legend {
        img {
            width: 233px;
        }
    }

    .images_list {
        img {
            width: 337px;
            margin-bottom: 40px;
        }
    }

    @media screen and (min-width: 1200px) {
        width: 100%;
        max-width: 1170px;
        margin: 0 auto;
        .title_image_legend_header {
            display: flex;
            padding-right: 10px;

            .title, .legend {
                width: 50%;
            }
            .legend {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
            }
        }

        .images_list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}


.title {
    transition: opacity 0.2s ease-in;
    transition-delay: 0.1s;
}

.legend {
    transition: opacity 0.2s ease-in;
    transition-delay: 0.15s;
}

.images_list {
    transition: transform 0.3s ease-in;
    transition-delay: 0.2s;
}
