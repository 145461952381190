@import './../../globalStyles/variables';

.inview-false {
    .footer_connect, .footer_logo, .footer_bottom_social, .footer_bottom_parentlogo {
        opacity: 0;
    }
    .footer_connect_inner {
        transform: translateY(40px);
    }

}

.inview-true {
    .footer_connect, .footer_logo, .footer_bottom_social, .footer_bottom_parentlogo {
        opacity: 1;
    }
    .footer_connect_inner {
        transform: translateY(0px);
    }

}

.footer_connect{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in;

    .footer_connect_background {
        z-index: -1;
        width: 100%;
        background: var(--secondary);
        
        img {
            opacity: 0.2;
            height: 226px;
        }
    }

    .footer_connect_inner {
        position: absolute;
        font-weight: 300;
        line-height: 2;
        letter-spacing: 0.7px;
        text-align: center;
        color: var(--white);
        width: 219px;
        transition: transform 0.2s ease-in;
        transition-delay: 0.05s;        
    }

    @media screen and (min-width: 1200px) {
        .footer_connect_background {
            img {
                height: auto;
            }
        }

        .footer_connect_inner {
            width: auto;
        }
    }
}

.footer_bottom {
    background-color: var(--primary);
    display: flex;
    justify-content: end;

    @media screen and (min-width: 1200px) {
        justify-content: center;
    }
}

.footer_bottom_inner {
    background-color: var(--primary);
    color: var(--white);
    padding: 40px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.18px;

    .footer_logo {
        transition: opacity 0.3s ease-in;
        transition-delay: 0.3s;
        
        img {
            width: 177px;
            padding-bottom: 15px;
        }

        .footer_address p {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.7px;
            opacity: 0.5;
        }
    }

    .footer_bottom_social {
        transition: opacity 0.3s ease-in;
        transition-delay: 0.4s;
        .social_icon_lable {
            line-height: 1.5;
            letter-spacing: 0.18px;
            color: #f1e7dd;
        }
        .social_icons {
            padding:13px 0 90px 0;
            display: flex;
                
            img {
                width: 33px;
                margin-right: 18px;
            }
            
            
        }
    }

    .footer_bottom_parentlogo {
        transition: opacity 0.3s ease-in;
        transition-delay: 0.5s;
        padding-bottom: 32px;
        display: flex;
        
        img {
            width: 160px;
            height: 100%;
            object-fit: contain;
            object-position: top right;
            padding-bottom: 15px;
        }
    }

    .footer_copyright {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.7px;
        opacity: 0.5;
    }

    .footer_links {
        opacity: 0.5;
        display: flex;
        flex-direction: column;
        padding-top: 30px;
            a {
                color: var(--white);
                letter-spacing: 0.18px;
                margin-bottom: 20px;
            }
    }

    @media screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 30% 30% 40%;
        width: 100%;
        max-width: 1200px;

        .footer_logo {
            padding-bottom: 33px;
        }

        .footer_bottom_parentlogo {
            flex-direction: row-reverse;
        }

        .footer_links {
            flex-direction: row;
            padding-top: 0px;
            a {
                margin-right: 20px;
                margin-bottom: 0px;
            }
        }
    }

    
}




