@import "./../../globalStyles/variables";

.cookie-footer {
  background: #dce2ed;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 10px 0;
  z-index: 7999;

  @media screen and (min-width: 800px) {
    padding: 50px 0;
  }

  .cookie-container {
    display: flex;
    flex-direction: column;
    padding: 0 15px;

    @media screen and (min-width: 800px) {
      flex-direction: row;
    }

    .cookie-title {
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 20px;
      color: #24272a;
      letter-spacing: 0;
      line-height: 25px;
      font-weight: 500;

      @media screen and (min-width: 800px) {
        text-align: left;
        width: 35%;
      }

      .cookie-title-holder {
        width: 100%;

        @media screen and (min-width: 800px) {
          width: 300px;
        }
      }
    }

    .cookie-description {
      width: 100%;
      line-height: 1.4;
      padding: 10px 30px;
      text-align: center;

      a {
        color: black;
      }

      @media screen and (min-width: 800px) {
        width: 50%;
        padding: 0 40px;
        text-align: left;
      }
    }

    .cookie-button {
      display: flex;
      justify-content: center;
      width: 100%;
      text-align: center;
      padding-left: 0px;

      @media screen and (min-width: 800px) {
        width: 25%;
        justify-content: center;
        padding-left: 35px;
      }

      .cookie-button-holder {
        color: white;
        height: 55px;
        border: 1px solid #415165;
        background-color: #415165;
        padding: 16px 45px;
        font-weight: 500;
        transition: all 0.2s;
      }

      .cookie-button-holder:hover {
        background-color: black;
        color: #fff;
      }
    }
  }
}
