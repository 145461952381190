// BASE SIZE
$baseSize: var(--basesize);

// COLORS
$colors: (
  base: var(--baseColor),
  primary: var(--primary),
  secondary: var(--secondary),
  white: var(--white),
);

// FONT FAMILIES
$fonts: (
  baseType: var(--basetype)
);


// GRID VARIABLES
$gridColumns: 12;
$gridGutterBase: 0.75rem;

// CONTAINERS
$gridContainer: 1280px;

$internalGridWidthOffset: calc( (var(--ratio) * 100vw) - var(--innerpadding) - ( (100vw - var(--container) ) / 2 ));


// BREAKPOINTS
$breakpoints: (
  break-0: 0,
  break-1: 567,
  break-2: 768,
  break-3: 1024,
  break-4: 1200
) !default;

$breakpointsfriendly: (
  mobile: #{map-get($breakpoints, break-0)}px,
  tablet: #{map-get($breakpoints, break-1)}px,
  desktop: #{map-get($breakpoints, break-2)}px,
  desktop-lg: #{map-get($breakpoints, break-3)}px,
  desktop-xl: #{map-get($breakpoints, break-4)}px
) !default;


// ROOT VARIABLES
:root {
    // Fonts
    --basetype: 'Flama', serif;
    --regular: 400;
    --bold: 700;
    --basesize: 16px;
    // Colors
    --base: #000000;
    --primary: #2f3f4e;
    --secondary: #4579a1;
    --white: #fff;
    --cherry: #ce0526;
    --light-grey: #f2f2f2;
    --medium-grey: #d8d8d8;
    --black: #000000;
    --passWCAG: #b3001d;
    --brick: #bf2929;
}
