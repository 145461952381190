@import './../../globalStyles/variables';

.inview-false {
    .press_richtextarea {
        opacity: 0;
    }
}

.inview-true {
    .press_richtextarea {
        opacity: 1;
    }
}

.press_richtextarea {
    transition: opacity 0.3s ease-in;
    padding: 20px 83px 20px 32px;
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.7px;
    color: #4c5b72;
    font-weight: 700;
    text-align: left;
    
    a {
        color: var(--secondary);
        font-weight: normal;
    }

    @media screen and (min-width: 1200px) {
        width: calc(469px * 2 + 30px);
        padding: 58px 0px 10px 0px;
        text-align: right;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        div {
            width: calc(100vw - 200px);
        }
    }
}
