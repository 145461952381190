.animate-false {
    &.pagetitle {
        opacity: 0;
    }
    .pagetitle_title, .pagetitle_background {
        transform: translateY(40px);
    }
}

.animate-true {
    &.pagetitle {
        opacity: 1;
    }
    .pagetitle_title, .pagetitle_background {
        transform: translateY(0px);
    }
}

.pagetitle {
    transition: opacity 0.3s ease-in;
    position: relative;;
    @media screen and (max-width: 768px) {
        figure.pagetitle_title {
            overflow: hidden;
        }
        figure.pagetitle_title img {
            width: 200%;
            max-width: 119%;
        }
    }
        
    @media screen and (min-width: 1200px) {

        
        .pagetitle_background {
            margin-top: 75px;
            img {
                max-height: 500px;
                object-fit: cover;
            }
        }
        
        .pagetitle_title {
            position: absolute;
            top: -75px;
            left: 0;
            z-index: 10;
            width: 650px;
        }

        &.oneline, &.higher-line {
            .pagetitle_background {
                margin-top: 44px;
            }
            
            .pagetitle_title {
                top: -44px;
            }
        }
        
    }

}

.pagetitle_title, .pagetitle_background {
    transition: transform 0.7s ease-in;
    transition-delay: 0.05s;
}
