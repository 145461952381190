.hero_slider {
    outline: none;
    &.animate-true {
        animation: fadeInOpacity 1s ease-in 0s 1;
    }

    &.title-true {
        .heroslider_title {
            overflow: hidden;
        }
        .heroslider_title img {
            width: 200%;
            max-width: 119%;
        }
        @media screen and (min-width: 1200px) {
            position: relative;
            padding-bottom: 80px;
            margin-bottom: 45px;
            .heroslider_title {
                position: absolute;
                z-index: 10;
                width: 650px;
                img {
                    width: 100%;
                }
            }
            .slider {
                top: 44px;
            }
        }
        


    }

    li {
        padding: 0;

        &.paging-item {
            border-bottom: none;
        }
    }


    
    .slider {
        outline: none;
        .slider-slide > img {
            max-height: 680px;
            object-fit: cover;
        }

        // Add arrows
        .slider-control-centerleft, .slider-control-centerright {
            button {
                visibility: hidden;

                &::after {
                    position: absolute;
                    visibility: visible !important;
                    display: block;
                    content: "";
                    background-repeat: no-repeat;
                    border: none;
                    width: 30px;
                    height: 30px;
                    background-size: contain;
                    bottom: 15px;
                    @media screen and (min-width: 1200px) {
                        width: 200%;
                        height: 60px;
                        margin-top: 10px;
                        bottom: 30px;
                    }
                }
            }
        }

        .slider-control-centerleft {
            button {
                &::after {
                background-image: url('./arrow-left.png');
                @media screen and (min-width: 1200px) {
                    left: 30px;
                }
            }
            }
        }

        .slider-control-centerright {
            button {
                &::after {
                    background-image: url('./arrow.png');
                    @media screen and (min-width: 1200px) {
                        left: 0;
                    }
                }

            }
        }
    }
}


@keyframes fadeInOpacity {
	0% {
        opacity: 0;
        transform: translateY(40px);
	}
	100% {
        opacity: 1;
        transform: translateY(0px);
	}
}
