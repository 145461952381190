.jumplink-single {
    height: 0;
    width: 0;
    overflow: hidden;
    display: block;
}

.sticky {
    z-index: 200;
    .JumplinksBar {
        transform: translateY(84px);
        @media screen and (min-width: 1000px) {
            transform: translateY(162px);
        }
    }
}

.JumplinksBar {
    background-color: #f7f7f7;
    .xcontainer {
        display: flex;
        max-width: 756px;
        margin: 0 auto;
        .singlejumplink {
            display: block;
            text-align: center;
            padding-top: 16px;
            padding-bottom: 11px;
            border-bottom: 6px solid #cbcfd2;
            font-size: 11px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2.03px;
            text-align: center;
            color: #2f3f4e;
            text-transform: uppercase;
            cursor: pointer;
            &:nth-child(2) {
                border-left: 1px solid #2f3f4e;
                border-right: 1px solid #2f3f4e;
            }
            @media screen and (min-width: 768px) {
                font-size: 14px;
                letter-spacing: 4.03px;
            }
            &::first-letter {
                font-weight: bold;
            }
            &.current-true {
                border-bottom: 5px solid #2f3f4e;
            }
        }
    }
}
