@import './../../globalStyles/variables';

.animate-false {
    .link-btn {
        opacity: 0;
        &.leftlink, &.rightlink {
            opacity: 0;
        }
        img {
            opacity: 0;
        }
    }
    .navpart {
        opacity: 0;
        ul {
            transform: translateX(40px);
        }
    }
}

.animate-true {
    .link-btn {
        opacity: 1;
        &.leftlink, &.rightlink {
            opacity: 1;
        }
        img {
            opacity: 1;
        }
    }
    .navpart {
        opacity: 1;
        ul {
            transform: translateX(0px);
        }
    }
}

header {
    background-color: var(--white);
    li {
        &::first-letter {
            font-weight: unset;
        }
    }
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 280;

    @media screen and (max-width: 999px) {
        box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.08);
    }
}

.close-container {
    box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 84px;
    padding: 20px;
}

.logoandlinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    font-size: 18px;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.86px;
    text-align: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 999px) {
        opacity: 0;
    }
    .link-btn {
        text-align: center;
        text-transform: uppercase;
        height: 44px;
        padding: 10px 5px;
        letter-spacing: 0.86px;
        font-stretch: condensed;
        line-height: 1;
        font-weight: 500;

        &.leftlink {
            border: solid 2px var(--primary);
            color: #2f3f4e;
            &:hover {
                color: white;
                background-color: var(--primary);
                border: solid 2px var(--primary);
            }
        }

        &.rightlink {
            background-color: var(--primary);
            color: var(--white);
            position: relative;
            padding: 11px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: solid 2px var(--white);
            &:before {
                display: inline-block;
                content: "";
                background-repeat: no-repeat;
                width: 100px;
                background-image: url('./download.svg');
                height: 17px;
                width: 17px;
                top: 12px;
                margin-right: 11px;
                background-position: left;
            }
            &:hover {
                color: var(--primary);
                background-color: var(--white);
                box-sizing: border-box;
                border: solid 2px var(--primary);
            }
        }

        
    }

    a {
        max-width: 200px;
        text-decoration: none;
        color: inherit;
    }

    .desktop-logo {
        max-width: 240px;
        img {
            animation: spinCreatebox .6s;
        }
        
    }

    @media screen and (min-width: 1200px) {
        height: 110px;
        margin: 0 auto;
        align-items: center;
        padding: 0;

        .link-btn {
            padding: 11px 32px;
        }
    }
    

}



.desktopMenu {
    display: flex;
    justify-content: center;
    background-color: var(--primary);
    height: 52px;

    @media screen and (max-width: 1001px) {
        display: none;
    }

    .navpart {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        
        li {
            padding: 15px 10px;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.18px;
            text-align: center;
            color: var(--white);
            border-bottom: none;
                a {
                    text-decoration: none;
                    color: inherit;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.5;
                    letter-spacing: 0.18px;
                }
        }
    }
}

.header-mobile {
    @media screen and (min-width: 1000px) {
        display: none;
    }
    .navbar {
        position: fixed;
        z-index: 100;
        display: flex;
        height: 80px;
        overflow: hidden;
        justify-content: space-between;
        align-items: center;
        background-color: var(--white);
        width: 100vw;
        top: 0;
        padding-left: 36px;
        left:0;
    }
    &.open-true {
        max-height: 100vh;
        overflow-y: scroll;
    }
    .logo {
        img {
            width: 143px;
        }
    }
    .toggleMenu {
        color: #42425D;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95px;
        height: 79px;
        &.isopen-true {
            color: var(--white);
            background-color: #42425D;
        }
        img {
            width: 20px;
            height: auto;
        }
    }
    .panel {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: 2000;
        overflow: hidden;
        background-color: var(--primary);
        animation: createBox .6s;

        .close-panel {
            padding: 10px;
            position: absolute;
            top: 0px;
            right: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 95px;
            height: 79px;
            img {
                width: 10px;
                height: 16px;
            }
        }
    }

    .logoandlinks-mobile {
        width: 100vw;
        position: absolute;
        top: 124px;
        margin: 0 auto;
        text-align: center;
        padding: 0 35px;
        margin-bottom: 30px;
        animation: fadeIn 1s ease-in 0s 1;

        img {
            width: 243px;
            margin: 0 auto;
        }

        .logoandlinks {
            padding: 40px 0;
            opacity: 1;
            .link-btn {
                &.leftlink {
                    border: solid 2px var(--white);
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.86px;
                    text-align: center;
                    color: #ffffff;
                    padding: 11px 34px;
                }

                &.rightlink {
                    background-color: #4579a1;
                    border: solid 2px #4579a1;
                    &:before {
                        left: 15px;
                    }
                }
            }
        }

        
    }

    .mobileChildren {
        padding: 47px 35px;
        position: fixed;
        top: 210px;
        margin-top: 30px;
        opacity: 1;
        animation: fadeInOpacity 2s ease-in 0s 1;
        
        li {
            border-bottom: none;

            a {
                text-decoration: none;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.3px;
                color: var(--white);
                &.mainMenuLink {
                    img {
                        width: 18px;
                    }
                }
            }
        }

        
    }
}

.link-btn {
    transition: opacity 0.3s ease-in;
    
    &.leftlink, &.rightlink {
        font-family: 'Flama';
        transition: opacity 0.6s ease-in;
        transition-delay: 0.2s;
    }

    &.rightlink {
        line-height: unset;
    }

    img {
        transition: opacity 0.8s ease-in;
        transition-delay: 0.2s;
    }

    .navpart {
        transition: opacity 1s ease-in;
        transition-delay: 0.5s;
    }
}

@keyframes fadeIn {
	0% {
        opacity: 0;
	}
	100% {
        opacity: 1;
	}
}

@keyframes fadeInOpacity {
	0% {
        opacity: 0;
        transform: translateY(40px);
	}
	100% {
        opacity: 1;
        transform: translateY(0px);
	}
}

@keyframes createBox {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes spin {
    from {
        opacity: 0;
        transform:rotate(0deg);
    }
    to {
        opacity: 1;
        transform:rotate(360deg);
    }
}

@keyframes spinCreatebox {
    from {
        opacity: 0;
        transform: scale(0);
        transform:rotateY(180deg)
    }
    to {
        opacity: 1;
        transform: scale(1);
        transform:rotateY(0deg)
    }
}
