// TEXT COLOR, BACKGROUND COLOR, FULL BLEED BACKGROUND
@each $colorName, $colorVal in $colors {
    .color-#{$colorName} {
        color: $colorVal;
    }
    .background-#{$colorName} {
        background-color: $colorVal;
    }
    .grid-container.full-bleed.background-#{$colorName} {
        position: relative;
        &::after {    
            background-color: $colorVal;
        }
    }
}

