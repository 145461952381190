
.inview-inner-false {
    .hightlights_img {
        opacity: 0;
    }
    .hightlights_content {
        transform: translateY(40px);
    }

    .hightlights_title {
        transform: translateX(40px);
    }

    .hightlights_items {
        opacity: 0;
    }

    .hightlights_features h3, .hightlights_features ul {
        transform: translateX(80px);
    }
}

.inview-inner-true {
    .hightlights_img {
        opacity: 1;
    }
    .hightlights_content {
        transform: translateY(0px);
    }

    .hightlights_title {
        transform: translateX(0px);
    }
    .hightlights_items {
        opacity: 1;
    }

    .hightlights_features h3, .hightlights_features ul {
        transform: translateX(0px);
    }
}


.hightlights {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @media screen and (max-width: 1199px) {
        .hightlights_img {
            position: relative;
            &:after {
                content: " ";
                position: absolute;
                display: block;
                width: 100vw;
                height: 100%;
                background: #dfe7ef;
                left: -48px;
                z-index: -1;
                top: 50%;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        .hightlights_top, .hightlights_bottom {
            margin: 0 auto;
            width: 100vw;
            display: flex;
            justify-content: center;
        }

        .hightlights_img  {
            transform: translateY(50%);
        }

        .hightlights_top_inner, .hightlights_bottom_inner {
            display: flex;
            flex-direction: row;
            max-width: 1200px;
            width: calc((100vw) - ((100vw - 1200px)/2));
            
            .hightlights_inners {
                width: 50%;
                &.hightlights_content p{
                    max-width: 390px;
                }
            }
            .hightlights_item_title{
                width: fit-content;
            }

            .hightlights_features {
                transform: translateY(20%);
                margin-left: 150px;
                width: 350px;
                h3 {
                    font-size: 18px;
                }
            }

            .hightlights_img{
                position: relative;
            }
        }

        .hightlights_bottom {
            padding-bottom: 100px;
            height: 680px;
            
            .hightlights_bottom_inner {
                padding-top: 110px;
            }
        }
    
        
    }

    .hightlights_top, .hightlights_bottom {
        padding: 40px 48px;
        transition: opacity 0.3s ease-in;
    }

    .hightlights_bottom {
        background-color: #dfe7ef;

        .hightlights_list {
            padding-bottom: 40px;
            h3 {
                &.hightlights_title {
                    letter-spacing: 8.05px;
                    &:after {
                        background: none;
                    }
                }
            }

            .hightlights_items {
                padding-bottom: 17px;
                &.horizontal {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                }
    
                &.vertical {
                    display: flex;
                    flex-direction: column;
                }
            }

            .hightlights_item_title {
                font-size: 41.4px;
                font-weight: 300;
                color: #31404c;
            }
    
            .hightlights_item_desc {
                font-size: 10.6px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.68;
                letter-spacing: 1.88px;
                color: #31404c;
                white-space: pre-line;
                text-transform: uppercase;
                span {
                    display: block;
                    &::first-letter {
                        font-weight: 700;
                    }
                }
            }
        }

    }

    .hightlights_content {
        font-size: 14px;
        line-height: 12;
        letter-spacing: 0.7px;
        color: #415165;
        transition: transform 0.2s ease-in;
        transition-delay: 0.05s;
        p {
            strong {
                font-size: 20px;
                font-weight: unset;
            }
            &::first-letter {
                font-weight: bold;
            }
        }
    }

    .hightlights_img {
        transition: opacity 0.2s ease-in;
        transition-delay: 0.2s;
    }

    .hightlights_title {
        transition: transform 0.2s ease-in;
        transition-delay: 0.1s;
    }

    .hightlights_items {
        transition: opacity 0.2s ease-in;
        transition-delay: 0.2s;
    }

    .hightlights_features h3 {
        transition: transform 0.3s ease-in;
        transition-delay: 0.3s;
    }

    .hightlights_features ul {
        transition: transform 0.3s ease-in;
        transition-delay: 0.4s;
    }

}

