.grid-wrapper {
    padding: 0 calc(#{$gridGutterBase / 2});
    .grid-container {
        max-width: $gridContainer;
        margin: 0 auto;
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -$gridGutterBase;
            @include breakpoint(tablet) {
                margin-right: calc(#{$gridGutterBase} * -2);
            }
        }
        .col {
            // opacity: 0.5;
            height: 200px;
            margin-right: $gridGutterBase;
            @include breakpoint(tablet) {
                margin-right: calc(#{$gridGutterBase} * 2);
            }
        }
    }
}

$maxWidth: 100%;
// SPECIAL MOBILE WIDE ALIGN CLASS - DESIGNS BREAK GRID LOGIC
.col.mobile-wide-align {
    flex-basis: calc(((#{$maxWidth} - ((#{$gridColumns}) * #{$gridGutterBase})) / (#{$gridColumns} / 10)) + (11 * #{$gridGutterBase}));
    margin-left: calc(((#{$maxWidth} - ((#{$gridColumns}) * #{$gridGutterBase})) / (#{$gridColumns})));          
    @include breakpoint(tablet) {
        margin-left: 0px;
    }
}

// GRID SPAN AND BREAKPOINT CALCS
@each $breakpoint, $breakpointVal in $breakpointsfriendly {   
    @for $i from 1 to $gridColumns {
        @include breakpoint($breakpoint) {
            // COLUMN SPAN
            .col.#{$breakpoint}-span-#{$i} {
                flex-basis: calc(((#{$maxWidth} - ((#{$gridColumns}) * #{$gridGutterBase})) / (#{$gridColumns} / #{$i})) + ((#{$i} - 1) * #{$gridGutterBase}));          
            } 
            // COLUMN OFFSET
            .col.#{$breakpoint}-offset-#{$i} {    
                margin-left: calc(((#{$maxWidth} - ((#{$gridColumns}) * #{$gridGutterBase})) / (#{$gridColumns} / #{$i})) + (#{$i} * #{$gridGutterBase}));          
            }
            .col.#{$breakpoint}-offset-0 {    
                margin-left: 0px;
            }
        }
    }
}

// RECALCULATION AFTER TABLET - GUTTER WIDTH CHANGES
@include breakpoint(tablet) {
    @each $breakpoint, $breakpointVal in $breakpointsfriendly {   
        @for $i from 1 to $gridColumns {
            @include breakpoint($breakpoint) {
                // COLUMN SPAN
                .col.#{$breakpoint}-span-#{$i} {
                    flex-basis: calc(((#{$maxWidth} - ((#{$gridColumns}) * (#{$gridGutterBase} * 2))) / (#{$gridColumns} / #{$i})) + ((#{$i} - 1) * (#{$gridGutterBase} * 2)));        
                }  
                // COLUMN OFFSET
                .col.#{$breakpoint}-offset-#{$i} {
                    margin-left: calc(((#{$maxWidth} - ((#{$gridColumns}) * (#{$gridGutterBase} * 2))) / (#{$gridColumns} / #{$i})) + (#{$i} * (#{$gridGutterBase} * 2)));
                }
                .col.#{$breakpoint}-offset-0 {    
                    margin-left: 0px;
                }
            }
        }
    }   
}

// FULL BLEED BACKGROUND 
.grid-container.full-bleed::after {
    content: '';
    display: block;
    width: 100vw;
    height: 100%;
    top: 0;
    position: absolute;
    left: calc(#{$gridGutterBase / -2});
    z-index: -1;
    @include gridContainerBreakpoint() {
        left: calc((100vw - #{$gridContainer}) / -2);
    }
}

// GRID DEBUGGER
@each $breakpoint, $breakpointVal in $breakpointsfriendly {
    $gutterWidth: $gridGutterBase;
    @if $breakpoint == mobile {
        $gutterWidth: $gridGutterBase;
    } @else {
        $gutterWidth: calc(#{$gridGutterBase} * 2);
    }
    @include breakpoint($breakpoint) {
        $totalgutterwidth: calc((#{$gridColumns} - 1) * (#{$gutterWidth}));
        $percentage: calc((100% - #{$totalgutterwidth}) / #{$gridColumns});
        .grid-container.debug {
            background-image: repeating-linear-gradient(
                to right, 
                rgba(0,0,0,0.4),
                rgba(0,0,0,0.4) calc(#{$percentage}), 
                transparent calc(#{$percentage}),
                transparent calc(#{$percentage} + (#{$gutterWidth})),
            );
        }
    } 
}


