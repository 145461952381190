html{ 
    max-width: 100vw;
    width: 100vw;
    overflow-x: hidden;
}

body {
    width: 100vw;
    max-width: 100vw;
    font-family: var(--basetype);
}

img {
    width: 100%;
    max-width: 100%;
    display: block;
    height: auto;
}

.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.iframes {
    width: 100%;
    height: 520px;

    @media screen and (max-width: 800px) {
        height: 258px;
    }
}

.MainContent > .row, .container {
    max-width: 1320px;
    margin: 30px auto;
    padding: 0px 20px;
}

textarea:focus, input:focus{
    outline: none;
}

.button {
    color: var(--primary);
    display: inline-block;
    padding: 11px 24px 11px 19px;
    border: solid 2px currentColor;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: 0.86px;
    font-stretch: condensed;
    line-height: 1;
    
    &:hover {
        color: white;
        background-color: var(--primary);
        border: solid 2px var(--primary);
    }

    &.button_revert {
        color: var(--white);
        background-color: var(--primary);
        border: solid 2px var(--primary);
        &:hover {
            color: var(--primary);
            background-color: var(--white);
            border: solid 2px var(--white);
        }
    }
}

.multi-story-btn1 {
    position: relative;
    padding: 11px 24px 11px 44px;
    cursor: pointer;
    &:before {
        display: block;
        content: "";
        background-repeat: no-repeat;
        position: absolute;
        width: 100px;
        background-image: url('./assets/virtual-button-icon.svg');
        height: 17px;
        width: 17px;
        top: 12px;
        background-position: left;
        left: 20px;
    }
}

.multi-story-btn2 {
    position: relative;
    padding: 11px 24px 11px 44px;
    text-align: center;
    cursor: pointer;
    &:before {
        display: block;
        content: "";
        background-repeat: no-repeat;
        position: absolute;
        width: 100px;
        background-image: url('./assets/virtual-button-icon.svg');
        height: 17px;
        width: 17px;
        top: 12px;
        background-position: left;
        left: 29px;
    }
}

.multi-story-btn1 {
    margin-bottom: 40px;
    &:before {
        background-image: url('./assets/virtual-button-icon.svg');
    }
}

.multi-story-btn2 {
    width: 233.64px;
    &:before {
        background-image: url('./assets/construction-button-icon.svg');
    }
}

@import './legacyGridSupport';
