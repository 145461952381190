body, html {
    font-family: 'Flama', sans-serif;
    font-size: 16px;
}

p {
    font-size: 1rem;
    line-height: 1.75;
    margin-bottom: 36px;
    letter-spacing: 0.8px;
}

strong, b {
    color: #4c5b71;
}
em, i {}

h1 {
    font-size: 17.6px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 6.26px;
    color: #31404c;
    text-transform: uppercase;
    padding: 40px 110px;
    background-color: #dfe7ef;
    max-width: 610px;
    position: relative;
    margin-bottom: 40px;
    &:before, &:after {
        display: block;
        content: "";
        background-repeat: no-repeat;
        position: absolute;
        width: 200%;
    }
    &:before {
        background-image: url('./assets/line2.png');
        height: 17px;
        background-position: right;
        margin-bottom: 10px;
        top: 30px;
        right: 0;
    }
    &:after {
        background-image: url('./assets/line1.png');
        height: 19px;
        margin-top: 10px;
        bottom: 30px;
        left: 0;
    }
    @media screen and (min-width: 600px) {
        padding: 43px 140px;
        font-size: 25.1px;
        font-weight: 300;
        letter-spacing: 8.94px;
    }
}

h1, h2, h3, h4, li {
    &::first-letter {
        font-weight: 700;
    }
}

h2 {
    font-size: 28px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 8.05px;
    color: #31404c;
    margin-bottom: 40px;
    text-transform: uppercase;
    @media screen and (max-width: 1001px) {
        font-size: 20px;
    }
}

h3 {
    font-size: 28px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 5.18px;
    color: #31404c;
    text-transform: uppercase;
    margin-bottom: 28px;
    &:after {
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        margin-top: 13px;
        background-color: var(--primary);
    }

    @media screen and (max-width: 1000px) {
        font-size: 22px;
    }
}

h4 {
    font-size: 16px;
    line-height: 1.56;
    font-weight: 300;
    font-size: 28px;
    letter-spacing: 8.05px;
    color: #3a4957;
    text-transform: uppercase;
}

ul, ol {
    margin-bottom: 20px;
    li {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.18px;
        color: #31404c;
        padding: 12px 0px;
        text-transform: uppercase;
        border-bottom: solid 1px #abb0b6;
    }
}

table {
    margin-bottom: 20px;
    tr {
        th {
            font-weight: 700;
            text-transform: uppercase;
        }
        td, th {
            border-bottom: solid 1px #abb0b6;
            padding: 12px 0px;
            text-align: left;
        }
    }
}

.content-container {
    padding-top: 162px;
    @media screen and (max-width: 1000px) {
        padding-top: 82px;
    }
    max-width: 100vw;
    width: 100vw;
    overflow-x: hidden;
}
