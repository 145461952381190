.inview-false {
    .text_image_toggle, .image_toggle {
        opacity: 0;
    }

    .text_image_toggel_content_inner {
        transform: translateX(-60px);
    }
}

.inview-true {
    .text_image_toggle, .image_toggle {
        opacity: 1;
    }
    .text_image_toggel_content_inner {
        transform: translateX(0px);
    }
}


.text_image_toggle {
    transition: opacity 0.2s ease-in;
    width: 100vw;
    max-width: 100vw;
    .background_img {
        display: none;
    }

    .text_image_toggel_content {
        padding: 40px 48px 20px 47px;
        font-size: 14px;
        line-height: 2;
        letter-spacing: 0.7px;
        color: #415165;
        p {
            font-size: 14px;
            line-height: 2;
            letter-spacing: 0.7px;
        }
    }

    .toggle_image {
        width: 100vw;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .toggle_buttons {
        display: flex;
        flex-basis: 0;
        width: 100vw;
        padding-bottom: 50px;

        .toggle_button {
            margin: 0;
            padding: 13px 15px;
            width: calc(100% / 3);
            text-align: center;
            font-size: 13px;
            font-weight: 300;
            border-top: 5px solid rgba(47, 63, 78, 0.21);
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 4.03px;
            text-align: center;
            color: #31404c;
            &.toggle-true {
                position: relative;
                &:before {
                    content: "";
                    display: block;
                    width: calc(100% + 2px);
                    height: 16px;
                    top: -16px;
                    position: absolute;
                    z-index: 2;
                    left: -1px;
                    background-color: rgba(47, 63, 78, 1);
                }
            }
            &::first-letter {
                font-weight: bold;
            }
        }

        .toggle_button:nth-child( 2n ) {
            border-right: solid 1px #2f3f4e;
            border-left: solid 1px #2f3f4e;
        }
    }
}

@media screen and (min-width: 1200px) {
    .text_image_toggle {
        display: grid;
        position: relative;
        grid-template-columns: 1fr 1fr;
        .background_img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            overflow: hidden;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 84%);
            }
        }

        .image_toggle {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .text_image_toggel_content {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .text_image_toggel_content_inner {
                padding-left: 111px;
            }
        }
        .toggle_button {
            font-size: 14px;
        }
        .toggle_image, .toggle_buttons  {
            max-width: 765px;
        }
    }
}

.text_image_toggel_content_inner {
    transition: transform 0.2s ease-in;
    transition-delay: 0.2s;
}

.image_toggle {
    transition: opacity 0.4s ease-in;
    transition-delay: 0.35s;
}


// 14inch screen
@media screen and (min-width: 1100px) {
    .text_image_toggel_content_inner {
        width: calc((100vw * 0.4) - (100vw - 1200px)/2);
    }
}

@media screen and (min-width: 1370px) {
    .text_image_toggel_content_inner {
        width: calc((100vw * 0.5) - (100vw - 1200px)/2);
    }
}

