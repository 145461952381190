@import './mixins';

$widths: 75, 66, 58, 50, 42, 33, 25, 16;
$pushes: 8, 16, 25, 33, 42, 50, 58, 66;

.MainContent {
    .col {
        p, ul, ol {
            + h3, h4, h5 {
                margin-top: 4rem;
            }
        }
    }
    .row {
        display: flex;
        flex-wrap: wrap;
        > .col {
            .container {
                padding: 0px;
                margin: 0px auto;
            }
            width: 100%;
            flex-basis: 1;
            &:first-child {
                padding-left: 0px;
            }
            &:last-child {
                padding-right: 0px;
            }
            @each $width in $widths {
                &.sm-w#{$width} {
                    width:  calc(#{$width+"%"});
                }
                @include breakpoint(tablet) {
                    &.md-w#{$width} {
                        width:  calc(#{$width+"%"});
                    }
                }
                @include breakpoint(desktop) {
                    &.lg-w#{$width} {
                        width:  calc(#{$width+"%"});
                    }
                }
            }
        }
    }
    
    .row {
        > .col {
            padding-right: 55px;
            padding-left: 55px;
            &:first-child {
                padding-left: 0px;
            }
            &:last-child {
                padding-right: 0px;
            }
        }
        &.no-wide-gutter {
            > .col {
                padding-right: 12px;
                padding-left: 12px;
            }
        }
        &.table {
            justify-content: space-between;
            align-content: center;
            align-items: center;
        }
    }
}

.hidden-sm {
    @include breakpoint(tablet, max) {
        display: none;
    }
}
.hidden-md {
    @include breakpoint(desktop, max) {
        display: none;
    }
}
.hidden-lg {
    @include breakpoint(desktop) {
        display: none;
    }
}

// .hidden-[lg, sm, md] {
  
// }