.modalContainer {
    @media screen and (max-width: 1001px) {
        z-index: 300;
    }
    position:fixed;
    background: white;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 300;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    white-space: nowrap;

    .modal_buttons {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        flex-direction: column;
        padding: 27px;
        z-index: 100;
    }

    .modalbtn {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 40px;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
            box-shadow: 0 13px 17px 1px rgba(0,0,0,0.14), 0 4px 22px 3px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20);
        }
        &.close {
            box-shadow: 0 13px 17px 1px rgba(0,0,0,0.14), 0 4px 22px 3px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20);
            transform: rotate(45deg);
            &:hover {
                box-shadow: none;
            }
        }


        img {
            width: 34px;
        }
    }
    

    .modalimage {
        display: block;
        width: 100vw;
        height: 100vh;
        > div {
            width: 100vw;
            height: 100vh;
        }
        &.is-vertical-true {
            justify-content: center;
            .react-transform-component {
                width: 100vw;
            }
        }

        .react-transform-element {
            img {
                transform: scale(0.5);
                align-self: baseline;
            }
        }
    }
} 

.zoom-false {
    position: relative;
    cursor: pointer;
    .zoom-button {
        height: 34px;
        width: 34px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    video {
        max-width: 750px;
    }
    @media screen and (max-width: 1001px) {
        display: flex;
        justify-content: center;
        align-items: baseline;
        img {
            align-self: baseline;
        }
        video {
            width: 92vw;
        }
    }
}

.zoom-true {
    .zoom-button {
        display: none;
    }
    
}
